<template>
  <div>
    <vx-card class="install-card-tab">
      <div class="card-title">
        <h2 class="mb-2">Connectors</h2>
        <p>
          Connectors gives you an easy way to share your data with other
          applications you own. To get started click on an application you would
          like to connect to below.
        </p>
      </div>
      <div class="visual-connectors">
        <h3 class="mb-2">Active Connectors</h3>
        <div class="connectors-card-main">
          <div
            class="connectors-card"
            v-for="(connector, index) in activeConnectors"
            :key="index"
            @click="getConnectorDetail(connector.ConnectorAppId)"
            title="Click to Edit"
          >
            <img
              :src="'data:image/png;base64,' + connector.Image"
              alt="connector-icon"
            />
            <div class="connector-card-text">
              <span>{{ connector.Name }}</span>
              <p>{{ connector.Description }}</p>
            </div>
            <feather-icon
              :class="connector.HasError ? 'info-red' : 'check-icon'"
              :icon="connector.HasError ? 'InfoIcon' : 'CheckIcon'"
            ></feather-icon>
          </div>
          <p v-if="!activeConnectors.length > 0">No Active Connector</p>
        </div>
      </div>
      <div class="visual-connectors">
        <h3 class="mb-2">+Person Available Mail and Newsletter Connectors</h3>
        <p class="mb-5">
          Use our Mail and Newsletter Connector to automatically push identified
          leads to your connector's selected mail list. The lead information
          sent to your system will include names, email addresses, and physical
          addresses. The connector will automatically query your list to prevent
          duplicates.
        </p>
        <div class="connectors-card-main">
          <div
            class="connectors-card"
            v-for="(connector, index) in inActiveConnectors"
            :key="index"
            @click="getConnectorDetail(connector.ConnectorAppId)"
            title="Click to Connect"
          >
            <img
              :src="'data:image/png;base64,' + connector.Image"
              alt="connector-icon"
            />
            <div class="connector-card-text">
              <span>{{ connector.Name }}</span>
              <p>{{ connector.Description }}</p>
            </div>
            <feather-icon
              class="check-icon disabled"
              icon="CheckIcon"
            ></feather-icon>
          </div>
          <p v-if="!inActiveConnectors.length > 0">No Connector Available</p>
        </div>
      </div>
      <div class="visual-connectors" v-if="appRunningOnDev == 'true'">
        <h3 class="mb-2">
          eCommerce Available Mail and Shopping Cart Connectors
        </h3>
        <p class="mb-5">
          Use our Mail and Newsletter Connector to automatically push abandoned
          cart information to your connector's selected mail list. The lead
          information sent to your system will include
          <span class="text-danger"
            >names, email addresses and physical addresses.</span
          >
        </p>
        <div class="connectors-card-main">
          <div
            class="connectors-card"
            v-for="(connector, index) in eCommerceConnectors"
            :key="index"
            @click="getConnectorDetail(connector.ConnectorAppId)"
            title="Click to Connect"
          >
            <img
              :src="'data:image/png;base64,' + connector.Image"
              alt="connector-icon"
            />
            <div class="connector-card-text">
              <span>{{ connector.Name }}</span>
              <p>{{ connector.Description }}</p>
            </div>
            <feather-icon
              class="check-icon disabled"
              icon="CheckIcon"
            ></feather-icon>
          </div>
          <p v-if="!eCommerceConnectors.length > 0">No Connector Available</p>
        </div>
      </div>
    </vx-card>
    <vs-popup
      class="connector-popup"
      :active.sync="isConnectorsSettingsPopupActive"
    >
      <div v-if="connectorDetail">
        <div class="visual-connectors mb-4">
          <div class="connectors-card-main">
            <div class="connectors-card mb-0">
              <img :src="'data:image/png;base64,' + connectorDetail.Image" />
              <div class="connector-card-text">
                <span
                  >Connector -
                  <span class="connectors-name">{{
                    connectorDetail.Name
                  }}</span></span
                >
              </div>
              <feather-icon
                v-if="connectorDetail.IsActive && !connectorDetail.HasError"
                class="check-icon"
                icon="CheckIcon"
              ></feather-icon>
              <feather-icon
                v-if="!connectorDetail.IsActive && !connectorDetail.HasError"
                class="check-icon disabled"
                icon="CheckIcon"
              ></feather-icon>
              <feather-icon
                v-if="connectorDetail.HasError"
                class="info-red"
                icon="InfoIcon"
              ></feather-icon>
            </div>
          </div>
          <div class="connectors-help" v-if="!connectorDetail.HideHelpText">
            <a :href="connectorDetail.HelpLink" target="_blank"
              >How to get your connectors information</a
            >
            <vx-tooltip
              color="primary"
              text="Click here to find your connector information."
            >
              <feather-icon
                class="check-icon disabled"
                icon="HelpCircleIcon"
              ></feather-icon>
            </vx-tooltip>
          </div>
        </div>
        <div class="connectors-form" v-if="!connectorDetail.IsWebHook">
          <form>
            <p class="error-text" v-if="connectorDetail.ErrorMessage">
              {{ connectorDetail.ErrorMessage }}
            </p>
            <div class="connectors-input">
              <label for="Classification">Classification</label>
              <vs-input
                :disabled="true"
                class="w-full"
                id="Classification"
                v-model="connectorDetail.Classification"
              />
            </div>
            <div class="connectors-input" v-show="connectorDetail.UseCustomerName">
              <label for="CustomerName">Customer Name</label>
              <vs-input
                class="w-full"
                id="Apikey"
                v-model="connectorDetail.CustomerName"
              />
            </div>
            <div class="connectors-input" v-show="connectorDetail.UseApiKey">
              <label for="Apikey">Apikey</label>
              <vs-input
                class="w-full"
                id="Apikey"
                v-model="connectorDetail.ApiKey"
              />
            </div>
            <div class="connectors-input" v-show="connectorDetail.UseUrl">
              <label for="Url">Url</label>
              <vs-input class="w-full" id="Url" v-model="connectorDetail.Url" />
            </div>
            <div class="connectors-input" v-show="connectorDetail.UseAppId">
              <label for="AppId">App Id</label>
              <vs-input
                class="w-full"
                id="AppId"
                v-model="connectorDetail.AppId"
              />
            </div>
            <div class="connectors-input" v-show="connectorDetail.UseAccountId">
              <label for="AccountId">Account Id</label>
              <vs-input
                class="w-full"
                id="AccountId"
                v-model="connectorDetail.AccountId"
              />
            </div>
            <div class="connectors-input" v-show="connectorDetail.UseClientID">
              <label for="ClientId">Client Id</label>
              <vs-input
                class="w-full"
                id="ClientId"
                v-model="connectorDetail.ClientID"
              />
            </div>
            <div class="connectors-input" v-show="connectorDetail.UseUserName">
              <label for="Username">Username</label>
              <vs-input
                class="w-full"
                id="Username"
                v-model="connectorDetail.UserName"
              />
            </div>
            <div class="connectors-input" v-show="connectorDetail.UsePassword">
              <label for="Password">Password</label>
              <vs-input
                type="password"
                class="w-full"
                id="Password"
                v-model="connectorDetail.Password"
              />
            </div>
            <div class="connectors-input" v-show="connectorDetail.UsePublicKey">
              <label for="PublickKey">Public Key</label>
              <vs-input
                class="w-full"
                id="PublickKey"
                v-model="connectorDetail.PublicKey"
              />
            </div>
            <div
              class="connectors-input"
              v-show="connectorDetail.UsePrivateKey"
            >
              <label for="PrivateKey">Private Key</label>
              <vs-input
                class="w-full"
                id="PrivateKey"
                v-model="connectorDetail.PrivateKey"
              />
            </div>
            <div
              class="connectors-input"
              v-if="connectorDetail.IsActive && connectorDetail.UseMailList"
            >
              <label for="MailList"
                >Mail List
                <a href="#" @click="getConnectorMailList()"
                  >(Change Mail List)</a
                ></label
              >
              <vs-input
                class="w-full"
                id="MailList"
                v-model="connectorDetail.MailListName"
                :readonly="connectorDetail.IsActive"
                :disabled="connectorDetail.IsActive"
              />
            </div>
            <div
              class="connectors-input"
              v-if="
                connectorDetail.IsActive && connectorDetail.UseTagSingleText
              "
            >
              <label for="TagName">Tag Name</label>
              <vs-input class="w-full" id="TagName" v-model="tagFreeTextName" />
            </div>
            <div
              class="connectors-input"
              v-if="(connectorDetail.IsActive && connectorDetail.UseTagFromList) || (connectorDetail.IsActive && connectorDetail.TagRequired)"
            >
              <label for="TagName">Tag Name</label>
              <v-select
                v-if="!connectorDetail.TagRequired"
                v-model="tagListSelected"
                :options="tagInfoOptions"
                name="tagListSelected"
                label="Value"
                :clearable="true"
              />
            
              <v-select
                v-else
                v-model="tagListSelected"
                :options="tagInfoOptions"
                name="tagListSelected"
                label="Value"
                :clearable="false"
              />
              <span
                class="w-full pr-4 text-danger text-sm" v-if="requiredTagValidationMessage">{{ requiredTagValidationMessage }}</span>
            </div>
            <!-- <div
            class="connectors-input"
            v-if="connectorDetail.IsActive && connectorDetail.TagRequired"
          >
          <v-select
                v-model="connectorDetail.tagListSelected"
                :options="tagInfoOptions"
                name="tagListSelected"
                label="Value"
                :clearable="true"
              />
          </div> -->
            <div class="connector-modal-button">
              <div
                class="float-left d-flex flex-wrap align-items-start mt-4 sm:w-auto"
              >
                <vs-button
                  v-if="!connectorDetail.IsActive"
                  class="primary-btn sm:w-auto mt-4"
                  @click="
                    connectorDetail.UseOAuth &&
                    connectorDetail.OAuthStatus != 'ok'
                      ? addEmailConnectorOAuth2Account()
                      : saveCustomerConnector(false)
                  "
                  >Connect</vs-button
                >
                <vs-button
                  v-if="connectorDetail.IsActive"
                  class="primary-btn mr-2 sm:w-auto mt-4"
                  @click="disconnectConnector()"
                  >Disconnect</vs-button
                >
                <vs-button
                  v-if="connectorDetail.UseSyncOption"
                  :disabled="
                    connectorDetail.UseSyncOption && !connectorDetail.IsActive
                  "
                  color="warning"
                  class="primary-btn sync-options sm:w-auto mt-4 mb-2 ml-2"
                  icon-pack="feather"
                  icon="icon-refresh-ccw"
                  @click="getSyncOptions(connectorDetail.ConnectorAppId)"
                  >Sync Options</vs-button
                >
                <vs-button
                  v-if="connectorDetail.ErrorMessage"
                  @click="saveCustomerConnector(false)"
                  class="primary-btn w-full sm:w-auto mt-4"
                  >Reconnect</vs-button
                >
              </div>
              <div class="float-right mt-4 sm:w-auto">
                <vs-button
                  @click="cancelConnectorsSettingsPopup()"
                  color="grey"
                  type="border"
                  class="grey-btn ml-0 sm:ml-4 sm:w-auto mt-4"
                  >Close</vs-button
                >
              </div>
            </div>
          </form>
        </div>
        <div class="connectors-form" v-if="connectorDetail.IsWebHook">
          <form>
            <div class="connectors-input">
              <label for="Classification">Postback URL</label>
              <vs-textarea
                v-model="postBackUrlStr"
                class="w-full float-none"
                id="mytextarea"
              />
            </div>
            <div class="sm:w-auto d-flex align-items-center justify-between">
              <div class="mt-2">
                <vs-button
                  class="primary-btn sm:w-auto mr-2"
                  @click="isAddWebHookUrlPopupActive = true"
                  >Add</vs-button
                >
                <vs-button
                  color="warning"
                  class="secondary-btn mr-0 sm:mr-4 sm:w-auto"
                  @click="openURLRemoveConfirmPopup()"
                  >Remove</vs-button
                >
              </div>
              <vs-button
                @click="
                  (connectorDetail.IsActive &&
                    connectorDetail.PostBackURLs.toString().replace(
                      /,/g,
                      '\n'
                    ) != postBackUrlStr) ||
                  (!connectorDetail.IsActive && postBackUrlStr)
                    ? (isFinalSavePopup = true)
                    : finalCancelConnectorsSettingsPopup()
                "
                color="grey"
                type="border"
                class="grey-btn sm:w-auto mt-2"
                >Close</vs-button
              >
            </div>
          </form>
        </div>
      </div>
      <vs-popup
        class="transaction-popup"
        :active.sync="confirmationToRemovePostBackUrlPopup"
      >
        <div class="transaction-details">
          <div class="popup-icon" v-if="urlSelectedToRemove">
            <img src="../../assets/images/warning.svg" />
          </div>
          <p v-if="urlSelectedToRemove">
            Your are about to remove {{ urlSelectedToRemove }} from the list. Do
            you wish to continue?
          </p>
          <p v-else>No URL is selected</p>
          <div
            class="float-left mt-6 w-full text-center"
            v-if="urlSelectedToRemove"
          >
            <!-- <vs-button @click="postBackUrlStr = '', confirmationToRemovePostBackUrlPopup = false" color="success" class="mt-0 sm:w-auto w-full" type="filled">Yes</vs-button> -->
            <vs-button
              @click="getSel()"
              color="success"
              class="mt-0 sm:w-auto w-full"
              type="filled"
              >Yes</vs-button
            >

            <vs-button
              @click="confirmationToRemovePostBackUrlPopup = false"
              color="grey"
              type="border"
              class="grey-btn sm:ml-4 ml-0 mt-2 sm:mt-0 sm:w-auto w-full"
              >{{ LabelConstant.buttonLabelNo }}</vs-button
            >
          </div>
          <div
            class="float-left mt-6 w-full text-center"
            v-if="!urlSelectedToRemove"
          >
            <vs-button
              @click="confirmationToRemovePostBackUrlPopup = false"
              color="grey"
              type="border"
              class="grey-btn sm:ml-4 ml-0 mt-2 sm:mt-0 sm:w-auto w-full"
              >{{ LabelConstant.buttonLabelOk }}</vs-button
            >
          </div>
        </div>
      </vs-popup>
      <vs-popup
        title="Add Postback URL"
        class="connectors-inner-popup webhook-inner"
        :active.sync="isAddWebHookUrlPopupActive"
      >
        <form data-vv-scope="addPostBackUrlForm">
          <label for="MailList" class="rotate-icon-label mb-1"
            >Postback URL</label
          >
          <vs-input
            class="w-full"
            placeholder="https://wwww.example.com"
            v-model="postBackUrl"
            name="postBackUrl"
            v-validate="'required|url:require_protocol'"
          />
          <span class="text-danger text-sm">{{
            errors.first("addPostBackUrlForm.postBackUrl")
          }}</span>
          <div
            class="d-flex mt-4 align-items-center justify-between flex-wrap mb-8"
          >
            <div class="mt-2 sm:w-auto">
              <vs-button
                :disabled="!postBackUrl"
                @click="sendWebHookPostBackTest()"
                color="warning"
                class="sm:w-auto"
                >Send Test Message</vs-button
              >
            </div>
            <div class="mt-2 sm:w-auto">
              <vs-button
                type="filled"
                class=""
                color="success"
                @click="addPostBackUrlToArr()"
                >Add</vs-button
              >
              <vs-button
                color="grey"
                type="border"
                class="grey-btn ml-2 sm:w-auto"
                @click="cancelAddPostBackUrl()"
                >Cancel</vs-button
              >
            </div>
          </div>
        </form>
      </vs-popup>
      <vs-popup
        title="Mail List"
        class="connectors-inner-popup"
        :active.sync="isMailListPopupActive"
      >
        <label for="MailList" class="rotate-icon-label"
          >Mail List<feather-icon
            @click="getConnectorMailList()"
            class="rotate-icon-feather"
            icon="RotateCwIcon"
        /></label>
        <v-select
          v-model="mailListSelected"
          :options="mailListOptions"
          name="mailListSelected"
          label="name"
          :clearable="false"
        />
        <div class="float-right mt-4 w-full sm:w-auto mb-8">
          <vs-button
            @click="cancelMailListSelect()"
            color="grey"
            type="border"
            class="grey-btn ml-0 sm:ml-4 w-full sm:w-auto mt-2"
            >Cancel</vs-button
          >
          <vs-button
            :disabled="!mailListSelected"
            type="filled"
            class="w-full sm:ml-4 sm:w-auto mt-2"
            color="primary"
            @click="selectMailList()"
            >OK</vs-button
          >
        </div>
      </vs-popup>
      <vs-popup
      title="Tag List"
      class="connectors-inner-popup"
      :active.sync="isTagListPopupActive"
    >
      <label for="TagList" class="rotate-icon-label"
        >Tag List</label>
      <v-select
        v-model="tagListSelected"
        :options="tagInfoOptions"
        name="tagListSelected"
        label="Value"
        :clearable="false"
       
      />
      <div class="float-right mt-4 w-full sm:w-auto mb-8">
        <vs-button
          @click="cancelTagListSelect()"
          color="grey"
          type="border"
          class="grey-btn ml-0 sm:ml-4 w-full sm:w-auto mt-2"
          >Cancel</vs-button
        >
        <vs-button
          :disabled="!tagListSelected"
          type="filled"
          class="w-full sm:ml-4 sm:w-auto mt-2"
          color="primary"
          @click="selectTagList()"
          >OK</vs-button
        >
      </div>
    </vs-popup>
      <vs-popup
        title="Congratulations"
        class="connectors-inner-popup"
        :active.sync="isConnectionConfirmationPopupActive"
      >
        <div v-if="connectorDetail">
          <template>
            <div class="vx-row mb-3">
              <div class="vx-col w-full text-center">
                <p>
                  Your <strong>{{ connectorDetail.Name }}</strong> connection is
                  complete. All future leads will be automatically added to your
                  <strong>{{ connectorDetail.MailListName }} </strong> list.
                </p>
              </div>
            </div>
          </template>
          <div class="text-center mt-6 mb-3">
            <vs-button
              color="primary"
              type="filled"
              class="mr-2"
              @click="isConnectionConfirmationPopupActive = false"
              >{{ LabelConstant.buttonLabelOk }}</vs-button
            >
          </div>
        </div>
      </vs-popup>
      <vs-popup class="transaction-popup" :active.sync="isFinalSavePopup">
        <div class="transaction-details">
          <div class="popup-icon">
            <img src="../../assets/images/warning.svg" />
          </div>
          <p>Changes were made. Would you like to save them?</p>
          <div class="float-left mt-6 w-full text-center">
            <vs-button
              @click="finalSaveCustomerConnector()"
              color="success"
              class="mt-0 sm:w-auto w-full"
              type="filled"
              >Yes</vs-button
            >

            <vs-button
              @click="finalCancelConnectorsSettingsPopup()"
              color="grey"
              type="border"
              class="grey-btn sm:ml-4 ml-0 mt-2 sm:mt-0 sm:w-auto w-full"
              >No</vs-button
            >

            <vs-button
              @click="isFinalSavePopup = false"
              color="grey"
              type="border"
              class="grey-btn sm:ml-4 ml-0 mt-2 sm:mt-0 sm:w-auto w-full"
              >{{ LabelConstant.buttonLabelCancel }}</vs-button
            >
          </div>
        </div>
      </vs-popup>
      <vs-popup
        title="Sync Options"
        class="connectors-inner-popup webhook-inner"
        :active.sync="isSyncOptions"
      >
        <div class="options-main-content" v-if="syncOptions">
          <div class="option-known-customer">
            <vs-checkbox
              name="syncCustomerEnabled"
              v-model="enableKnownCustomer"
              >Enable Known Customer and Associates Synchronization</vs-checkbox
            >
            <div class="option-known-inner mt-3">
              <vs-checkbox
                class="mb-2 ml-0"
                name="enableOverwriteExistingKnownCustomerList"
                v-model="enableOverwriteExistingKnownCustomerList"
                >Clear existing list when doing first sync</vs-checkbox
              >
              <label class="block w-full mb-2"
                >How often should customers be synced?</label
              >
              <div class="custom-modal-radio">
                <input
                  type="radio"
                  class="mr-2 ml-4 mb-2"
                  name="optionA1"
                  id="val1"
                  v-model="optionA1"
                  value="false1"
                /><label for="val1">One time only</label>
              </div>
              <div class="custom-modal-radio">
                <input
                  type="radio"
                  v-model="optionA1"
                  id="val2"
                  name="optionA1"
                  value="true1"
                  class="mb-2 mr-2 ml-4"
                /><label for="val2">Daily</label>
              </div>
            </div>
            <div class="option-known-inner mt-3">
              <label class="block w-full mb-2">Pull customers from?</label>
              <div class="custom-modal-radio">
                <input
                  type="radio"
                  class="mr-2 mb-2 ml-4"
                  id="val3"
                  name="optionB1"
                  v-model="optionB1"
                  value="true2"
                />
                <label for="val3">Sync all my customers</label>
              </div>
              <div class="custom-modal-radio">
                <input
                  type="radio"
                  id="val4"
                  v-model="optionB1"
                  name="optionB1"
                  value="false2"
                  class="mb-2 mr-2 ml-4"
                />
                <label for="val4">Use the mail list:</label>
                <a
                  href="#"
                  @click="
                    optionB1 == 'false2'
                      ? (getConnectorMailList(),
                        (isSyncTypeKnownCustomer = true))
                      : ''
                  "
                  >(Change Mail List)</a
                >
              </div>

              <vs-input
                class="w-full ml-5 pl-6 pr-4"
                placeholder="My Marketing List"
                v-model="syncMailListName"
                :readonly="connectorDetail.IsActive"
              />
              <span
                class="w-full ml-5 pl-6 pr-4 text-danger text-sm"
                v-if="knownSuppressionErrorMessage"
                >{{ knownSuppressionErrorMessage }}</span
              >
            </div>
          </div>
          <div class="option-known-customer mt-5">
            <vs-checkbox name="Synchronization" v-model="enableSuppression"
              >Enable Suppression List Synchronization</vs-checkbox
            >
            <div class="option-known-inner mt-3">
              <vs-checkbox
                class="mb-2 ml-0"
                name="enableOverwriteExistingSuppressionList"
                v-model="enableOverwriteExistingSuppressionList"
                >Clear existing list when doing first sync</vs-checkbox
              >
              <label class="block w-full mb-2"
                >How often should suppressed addressed be synced?</label
              >
              <div class="custom-modal-radio">
                <input
                  type="radio"
                  id="val5"
                  class="mr-2 mb-2 ml-4"
                  name="optionC1"
                  v-model="optionC1"
                  value="false3"
                />
                <label for="val5">One time only</label>
              </div>
              <div class="custom-modal-radio">
                <input
                  type="radio"
                  id="val6"
                  v-model="optionC1"
                  class="mb-2 ml-4 mr-2"
                  name="optionC1"
                  value="true3"
                />
                <label for="val6">Daily </label>
              </div>
            </div>
            <div class="option-known-inner mt-3">
              <div class="custom-modal-radio">
                <label for="val8">Pull suppresion list from?</label>
                <a
                  href="#"
                  @click="
                    getConnectorMailList(), (isSyncTypeKnownCustomer = false)
                  "
                  >(Change Mail List)</a
                >
                <vs-input
                  class="w-full ml-5 pl-6 pr-4"
                  placeholder="My Marketing List"
                  v-model="syncSuppressionMailListName"
                  :readonly="connectorDetail.IsActive"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex mt-4 align-items-center justify-end flex-wrap mb-8">
          <div class="mt-2 sm:w-auto">
            <vs-button
              type="filled"
              class=""
              color="primary"
              @click="checkForSyncOptionsValidation()"
              >Save</vs-button
            >
            <vs-button
              color="grey"
              type="border"
              class="grey-btn ml-2 sm:w-auto"
              @click="cancelSyncOptions()"
              >Cancel</vs-button
            >
          </div>
        </div>
        <vs-popup
          class="transaction-popup"
          :active.sync="isKnownCustomerWarningMessagePopup"
        >
          <div class="transaction-details">
            <div class="popup-icon">
              <img src="../../assets/images/warning.svg" />
            </div>
            <p>{{ syncOptionChangeOverwriteMessage }}</p>
            <div class="float-left mt-6 w-full text-center">
              <vs-button
                @click="
                  (isKnownCustomerWarningMessagePopup = false),
                    (syncOptionChangeOverwriteMessage = null),
                    saveSyncContacts()
                "
                color="grey"
                type="border"
                class="grey-btn sm:ml-4 ml-0 mt-2 sm:mt-0 sm:w-auto w-full"
                >Yes</vs-button
              >

              <vs-button
                @click="
                  (isKnownCustomerWarningMessagePopup = false),
                    (syncOptionChangeOverwriteMessage = null)
                "
                color="grey"
                type="border"
                class="grey-btn sm:ml-4 ml-0 mt-2 sm:mt-0 sm:w-auto w-full"
                >No</vs-button
              >
            </div>
          </div>
        </vs-popup>
      </vs-popup>
      <vs-popup
        class="transaction-popup"
        :active.sync="isSyncOptionsChangesInfoPopup"
      >
        <div class="transaction-details">
          <div class="popup-icon">
            <img src="../../assets/images/warning.svg" />
          </div>
          <p>
            The changes have been saved and may take a while to complete
            populating your contacts. An email will be sent when this is
            completed.
          </p>
          <div class="float-left mt-6 w-full text-center">
            <vs-button
              @click="
                (isSyncOptionsChangesInfoPopup = false),
                  (isKnownCustomerChange = false),
                  (isSuppressionChange = false)
              "
              color="grey"
              type="border"
              class="grey-btn sm:ml-4 ml-0 mt-2 sm:mt-0 sm:w-auto w-full"
              >OK</vs-button
            >
          </div>
        </div>
      </vs-popup>
    </vs-popup>
  </div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";
import vSelect from "vue-select";
import { Validator } from "vee-validate";

const dict = {
  custom: {
    postBackUrl: {
      required: "Please enter a valid url.",
      url: "Please enter a valid url.",
    },
  },
};

Validator.localize("en", dict);

export default {
  components: {
    VxCard,
    "v-select": vSelect,
  },
  data() {
    return {
      appRunningOnDev: process.env.VUE_APP_RUNNING_ON_DEV
        ? process.env.VUE_APP_RUNNING_ON_DEV
        : "",
      dashboardItemList: [],
      activeConnectors: [],
      inActiveConnectors: [],
      eCommerceConnectors: [],

      isConnectorsSettingsPopupActive: false,

      connectorDetail: null,

      isMailListPopupActive: false,
      mailListOptions: [],
      mailListSelected: null,

      isConnectionConfirmationPopupActive: false,

      tagInfoOptions: [],
      tagListSelected: null,
      tagFreeTextName: null,
      isTagListPopupActive:false,

      isFinalSavePopup: false,
      isEventListner: false,

      isAddWebHookUrlPopupActive: false,
      postBackUrl: null,
      postBackUrlStr: "",
      confirmationToRemovePostBackUrlPopup: false,
      urlSelectedToRemove: null,

      isSyncTypeKnownCustomer: false,
      isSyncOptions: false,
      syncOptions: null,
      enableKnownCustomer: false,
      enableOverwriteExistingKnownCustomerList: false,
      optionA1: "false1",
      optionB1: "false2",
      syncMailListId: "",
      syncMailListName: "",
      enableSuppression: false,
      enableOverwriteExistingSuppressionList: false,
      optionC1: "false3",
      syncSuppressionMailListId: "",
      syncSuppressionMailListName: "",

      isKnownCustomerWarningMessagePopup: false,
      syncOptionChangeOverwriteMessage: null,
      knownSuppressionErrorMessage: null,

      isSyncOptionsChangesInfoPopup: false,
      isKnownCustomerChange: false,
      isSuppressionChange: false,

      requiredTagValidationMessage: null,
    };
  },
  created() {},
  mounted() {
    this.getConnectorDashboardList();
    var that = this;
    window.addEventListener("focus", checkOauthStatus);

    function checkOauthStatus() {
      if (that.isEventListner) {
        that.getConnectorDetailOauthStatusCheck(
          that.connectorDetail.ConnectorAppId
        );
      }
      that.isEventListner = false;
    }
  },
  watch: {
    optionB1(value) {
      if (value == "true2") {
        if (this.syncOptions.SyncCustomerLocationId == "") {
          this.syncMailListId = "";
          this.syncMailListName = "";
        } else {
          this.syncMailListId = this.syncOptions.SyncCustomerLocationId;
          this.syncMailListName = this.syncOptions.SyncCustomerLocationName;
        }
      }
    },
  },
  methods: {
    async getConnectorDashboardList() {
      this.$vs.loading();
      await this.axios
        .get("/ws/Connector/GetConnectorDashboardList")
        .then((response) => {
          let data = response.data;
          this.dashboardItemList = data;
          this.activeConnectors = [];
          this.inActiveConnectors = [];
          this.eCommerceConnectors = [];
          for (let i = 0; i < this.dashboardItemList.length; i++) {
            if (this.dashboardItemList[i].IsActive) {
              this.activeConnectors.push(this.dashboardItemList[i]);
            } else {
              if (
                this.dashboardItemList[i].Classification == "abandoned-cart"
              ) {
                this.eCommerceConnectors.push(this.dashboardItemList[i]);
              } else {
                this.inActiveConnectors.push(this.dashboardItemList[i]);
              }
            }
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    async getConnectorDetailOauthStatusCheck(connAppId) {
      this.$vs.loading();
      await this.axios
        .get("/ws/Connector/GetConnectorDetail?&connAppId=" + connAppId)
        .then((response) => {
          let data = response.data;
          this.connectorDetail = data;
          if (
            this.connectorDetail.IsWebHook &&
            this.connectorDetail.PostBackURLs &&
            this.connectorDetail.PostBackURLs.length > 0
          ) {
            var tempStr = this.connectorDetail.PostBackURLs.toString();
            this.postBackUrlStr = tempStr.replace(/,/g, "\n");
          }
          if (
            this.connectorDetail.OAuthStatus == "ok" &&
            this.connectorDetail.UseMailList
          ) {
            this.getConnectorMailList();
          } else if (this.connectorDetail.OAuthStatus == "ok") {
            this.getConnectorTagList();
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Authentication failed!",
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-right",
              time: 4000,
            });
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    async getConnectorDetail(connAppId) {
      this.$vs.loading();
      await this.axios
        .get("/ws/Connector/GetConnectorDetail?&connAppId=" + connAppId)
        .then((response) => {
          let data = response.data;
          this.isConnectorsSettingsPopupActive = true;
          this.connectorDetail = data;
          if (
            this.connectorDetail.IsWebHook &&
            this.connectorDetail.PostBackURLs &&
            this.connectorDetail.PostBackURLs.length > 0
          ) {
            var tempStr = this.connectorDetail.PostBackURLs.toString();
            this.postBackUrlStr = tempStr.replace(/,/g, "\n");
          }
          if (this.connectorDetail.IsActive) {
            if (this.connectorDetail.UseTagFromList) {
              this.tagListSelected = this.connectorDetail.TagInfo;
              this.getConnectorTagList();
            }
            if (this.connectorDetail.UseTagSingleText) {
              this.tagFreeTextName = this.connectorDetail.TagInfo.Value;
            }
          }
          // else{
          //   if(this.connectorDetail.IsActive && this.connectorDetail.TagRequired && !this.connectorDetail.UseMailList){
          //     this.getConnectorTagList();
          //   }
          // }
        
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    async addEmailConnectorOAuth2Account() {
      this.$vs.loading();
      await this.axios
        .get(
          "/ws/DashboardConnectedAccounts/AddEmailConnectorOAuth2Account?connAppId=" +
            this.connectorDetail.ConnectorAppId
        )
        .then((response) => {
          let data = response.data;
          this.isEventListner = true;
          window
            .open(
              data,
              +"Oauth",
              "toolbar=0,scrollbars=1,location=0,status=0,menubar=0,resizable=1,width=650, height=550,left = 300,top=100"
            )
            .focus();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    cancelConnectorsSettingsPopup() {
      if (this.connectorDetail.IsActive) {
        if (this.connectorDetail.UseTagFromList && !this.connectorDetail.TagRequired) {
          // console.log(this.connectorDetail.TagInfo != this.tagListSelected);
          // console.log(this.connectorDetail.TagInfo);
          // console.log(this.tagListSelected);
          if ((this.connectorDetail.TagInfo != this.tagListSelected)) {
                this.isFinalSavePopup = true;  
            }
           else {
            this.isConnectorsSettingsPopupActive = false;
            this.connectorDetail = null;
            this.getConnectorDashboardList();
          }
        } else if(this.connectorDetail.TagRequired){
          if(this.connectorDetail.TagInfo != this.tagListSelected){
            this.isFinalSavePopup = true;  
          }
          else{
            this.isConnectorsSettingsPopupActive = false;
            this.connectorDetail = null;
            this.getConnectorDashboardList();
          }
            
        }
        else if (this.connectorDetail.UseTagSingleText) {
          if (this.connectorDetail.TagInfo.Value != this.tagFreeTextName) {
            this.isFinalSavePopup = true;
          } else {
            this.isConnectorsSettingsPopupActive = false;
            this.connectorDetail = null;
            this.getConnectorDashboardList();
          }
        } else {
          this.isConnectorsSettingsPopupActive = false;
          this.connectorDetail = null;
          this.getConnectorDashboardList();
        }
      } else {
        this.isConnectorsSettingsPopupActive = false;
        this.connectorDetail = null;
        this.getConnectorDashboardList();
      }
      this.requiredTagValidationMessage = null
    },
    async saveCustomerConnector(isFalse) {
        this.requiredTagValidationMessage = null
        this.$vs.loading();
        let params = {
          ConnectorAppId: this.connectorDetail.ConnectorAppId,
          IsActive: isFalse,
          ApiKey: this.connectorDetail.ApiKey,
          Url: this.connectorDetail.Url,
          CustomerName:this.connectorDetail.CustomerName,
          PublicKey: this.connectorDetail.PublicKey,
          PrivateKey: this.connectorDetail.PrivateKey,
          AppId: this.connectorDetail.AppId,
          UserName: this.connectorDetail.UserName,
          Password: this.connectorDetail.Password,
          AccountId: this.connectorDetail.AccountId,
          ClientID: this.connectorDetail.ClientID,
          MailListName: this.connectorDetail.MailListName,
          MailListId: this.connectorDetail.MailListId,
          TagId: this.tagListSelected ? this.tagListSelected.Key : "",
          TagValue: this.tagListSelected
            ? this.tagListSelected.Value
            : this.tagFreeTextName
            ? this.tagFreeTextName
            : "",
        };
        await this.axios
          .post("/ws/Connector/SaveCustomerConnector", params)
          .then(() => {
            if (!isFalse && this.connectorDetail.UseMailList) {
              this.getConnectorMailList()
            
              }else if(!isFalse && this.connectorDetail.TagRequired){
                this.getConnectorTagList();
              }else{
                // this.connectorDetail.IsActive = isFalse
                this.cancelTagListSelect();
                this.isConnectionConfirmationPopupActive = true;
                this.getConnectorDetail(this.connectorDetail.ConnectorAppId);
            }
            this.$vs.loading.close();
          })
          .catch((e) => {
            this.$vs.loading.close();
            this.showError(e);
          });
    },
    async getConnectorMailList() {
      this.$vs.loading();
      await this.axios
        .get(
          "/ws/Connector/GetConnectorMailList?connAppId=" +
            this.connectorDetail.ConnectorAppId
        )
        .then((response) => {
          let data = response.data;
          if (data.HasError) {
            let errorMessage =
              data.ErrorMessage.length > 0
                ? data.ErrorMessage
                : "Cannot connect to  the Mail/Newsletter provider";
            this.$vs.notify({
              title: "Error",
              text: errorMessage,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-right",
              time: 4000,
            });
          } else {
            this.mailListOptions = data.EmailList;
            this.isMailListPopupActive = true;
            if (this.connectorDetail && this.connectorDetail.MailListId != "") {
              this.mailListSelected = {
                id: this.connectorDetail.MailListId,
                name: this.connectorDetail.MailListName,
              };
            }
            if (
              this.isSyncTypeKnownCustomer &&
              this.syncOptions &&
              this.syncOptions.SyncCustomerLocationId != ""
            ) {
              this.mailListSelected = {
                id: this.syncOptions.SyncCustomerLocationId,
                name: this.syncOptions.SyncCustomerLocationName,
              };
            }
            if (
              !this.isSyncTypeKnownCustomer &&
              this.syncOptions &&
              this.syncOptions.SyncSuppressionLocationId != ""
            ) {
              this.mailListSelected = {
                id: this.syncOptions.SyncSuppressionLocationId,
                name: this.syncOptions.SyncSuppressionLocationName,
              };
            }
            this.getConnectorTagList();
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    async getConnectorTagList() {
      this.$vs.loading();
      await this.axios
        .get(
          "/ws/Connector/GetConnectorTagList?connAppId=" +
            this.connectorDetail.ConnectorAppId
        )
        .then((response) => {
          let data = response.data;
          this.tagInfoOptions = data;
          if(!this.connectorDetail.IsActive && this.connectorDetail.TagRequired){
            this.isTagListPopupActive = true;
            // if (this.connectorDetail && this.connectorDetail.TagId != "") {
            //   this.tagListSelected = {
            //     Key: this.connectorDetail.,
            //     Value: this.connectorDetail.TagInfo.Value,
            //   };
            // }
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    selectTagList(){
        // this.connectorDetail.TagId = this.tagListSelected.Key;
        // this.connectorDetail.TagValue = this.tagListSelected.Value;
        // console.log(this.connectorDetail.TagInfo.Key,"key");
        // console.log(this.connectorDetail.TagInfo.Value,"value")
        //this.cancelTagListSelect();
        this.saveCustomerConnector(true);
    },
    selectMailList() {
      if (!this.isSyncOptions) {
        this.connectorDetail.MailListId = this.mailListSelected.id;
        this.connectorDetail.MailListName = this.mailListSelected.name;
        this.cancelMailListSelect();
        this.saveCustomerConnector(true);
      } else {
        if (this.isSyncTypeKnownCustomer) {
          this.syncMailListId = this.mailListSelected.id;
          this.syncMailListName = this.mailListSelected.name;
          this.knownSuppressionErrorMessage = null;
        } else {
          this.syncSuppressionMailListId = this.mailListSelected.id;
          this.syncSuppressionMailListName = this.mailListSelected.name;
        }
        this.isSyncTypeKnownCustomer = false;
        this.cancelMailListSelect();
      }
    },
    cancelMailListSelect() {
      this.mailListSelected = null;
      this.isMailListPopupActive = false;
      this.mailListOptions = [];
    },
    cancelTagListSelect() {
      this.tagListSelected = null;
      this.isTagListPopupActive = false;
     // this.tagInfoOptions = [];
    },
    async finalSaveCustomerConnector() {
      if(this.connectorDetail.IsActive && this.connectorDetail.TagRequired == true && !this.tagListSelected){
        this.requiredTagValidationMessage = 'Please select a Tag first'
      }else{
      this.$vs.loading();
      let params = {
        ConnectorAppId: this.connectorDetail.ConnectorAppId,
        IsActive: this.connectorDetail.IsActive,
        ApiKey: this.connectorDetail.ApiKey,
        Url: this.connectorDetail.Url,
        CustomerName:this.connectorDetail.CustomerName,
        PublicKey: this.connectorDetail.PublicKey,
        PrivateKey: this.connectorDetail.PrivateKey,
        AppId: this.connectorDetail.AppId,
        UserName: this.connectorDetail.UserName,
        Password: this.connectorDetail.Password,
        AccountId: this.connectorDetail.AccountId,
        ClientID: this.connectorDetail.ClientID,
        MailListName: this.connectorDetail.MailListName,
        MailListId: this.connectorDetail.MailListId,
        TagId: this.tagListSelected ? this.tagListSelected.Key : "",
        TagValue: this.tagListSelected
          ? this.tagListSelected.Value
          : this.tagFreeTextName
          ? this.tagFreeTextName
          : "",
        PostBackURLs: this.postBackUrlStr.split("\n"),
      };
      await this.axios
        .post("/ws/Connector/SaveCustomerConnector", params)
        .then(() => {
          this.tagInfoOptions = [];
          this.tagListSelected = null;
          this.tagFreeTextName = null;
          this.finalCancelConnectorsSettingsPopup();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
      }
    },
    finalCancelConnectorsSettingsPopup() {
      this.isFinalSavePopup = false;
      this.isConnectorsSettingsPopupActive = false;
      this.connectorDetail = null;
      this.getConnectorDashboardList();
      this.postBackUrlStr = "";
    },
    async disconnectConnector() {
      this.$vs.loading();
      await this.axios
        .get(
          "/ws/Connector/DisconnectConnector?connAppId=" +
            this.connectorDetail.ConnectorAppId
        )
        .then(() => {
          this.$vs.notify({
            title: "Success",
            text: "Connector is disconnect now",
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
          this.tagInfoOptions = [];
          this.tagListSelected = null;
          this.tagFreeTextName = null;
          this.finalCancelConnectorsSettingsPopup();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    addPostBackUrlToArr() {
      this.$validator.validateAll("addPostBackUrlForm").then((result) => {
        if (result) {
          if (this.postBackUrlStr != "") {
            this.postBackUrlStr += "\n" + this.postBackUrl;
          } else {
            this.postBackUrlStr = this.postBackUrl;
          }
          this.cancelAddPostBackUrl();
        }
      });
    },
    cancelAddPostBackUrl() {
      this.postBackUrl = null;
      setTimeout(() => {
        this.errors.clear("addPostBackUrlForm");
      }, 20);
      this.isAddWebHookUrlPopupActive = false;
    },
    sendWebHookPostBackTest() {
      this.$validator.validateAll("addPostBackUrlForm").then((result) => {
        if (result) {
          this.$vs.loading();
          this.axios
            .get(
              "/ws/Connector/SendWebhookPostBackTest?&webhookURL=" +
                this.postBackUrl
            )
            .then(() => {
              this.$vs.notify({
                title: "Success",
                text: "Success",
                color: "success",
                iconPack: "feather",
                icon: "icon-check",
                position: "top-right",
                time: 4000,
              });
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.showError(e);
              this.$vs.notify({
                title: "Error",
                text: e.response.data.Errors[0].Message,
                color: "danger",
                iconPack: "feather",
                icon: "icon-alert-circle",
                position: "top-right",
                time: 4000,
              });
            });
        }
      });
    },
    async getSyncOptions(connAppId) {
      this.$vs.loading();
      await this.axios
        .get("/ws/Connector/GetConnectorSyncOptions?&connAppId=" + connAppId)
        .then((response) => {
          let data = response.data;
          this.isSyncOptions = true;
          this.syncOptions = data;
          this.enableKnownCustomer = this.syncOptions.SyncCustomerEnabled;
          this.enableOverwriteExistingKnownCustomerList =
            this.syncOptions.SyncCustomerOverwriteList;
          this.optionA1 =
            this.syncOptions.SyncCustomerDaily == false ? "false1" : "true1";
          this.optionB1 =
            this.syncOptions.SyncCustomerLocationId == "" ? "true2" : "false2";
          this.syncMailListId = this.syncOptions.SyncCustomerLocationId;
          this.syncMailListName = this.syncOptions.SyncCustomerLocationName;
          this.enableSuppression = this.syncOptions.SyncSuppressionEnabled;
          this.enableOverwriteExistingSuppressionList =
            this.syncOptions.SyncSuppressionOverwriteList;
          this.optionC1 =
            this.syncOptions.SyncSuppressionDaily == false ? "false3" : "true3";
          this.syncSuppressionMailListId =
            this.syncOptions.SyncSuppressionLocationId;
          this.syncSuppressionMailListName =
            this.syncOptions.SyncSuppressionLocationName;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    cancelSyncOptions() {
      this.isSyncTypeKnownCustomer = false;
      this.isSyncOptions = false;
      this.syncOptions = null;
      this.enableKnownCustomer = false;
      this.optionA1 = "false1";
      this.optionB1 = "false2";
      this.syncMailListId = "";
      this.syncMailListName = "";
      this.enableSuppression = false;
      this.optionC1 = "false3";
      this.syncSuppressionMailListId = "";
      this.syncSuppressionMailListName = "";
      this.knownSuppressionErrorMessage = null;
      this.isKnownCustomerChange = false;
      this.isSuppressionChange = false;
      this.enableOverwriteExistingKnownCustomerList = false;
      this.enableOverwriteExistingSuppressionList = false;
    },
    async saveSyncContacts() {
      this.$vs.loading();
      let payload = {
        ConnectorAppId: this.syncOptions.ConnectorAppId,
        SyncCustomerEnabled: this.enableKnownCustomer,
        SyncCustomerOverwriteList:
          this.enableOverwriteExistingKnownCustomerList,
        SyncCustomerDaily: this.optionA1 == "true1" ? true : false,
        SyncCustomerLocationId:
          this.optionB1 == "false2" ? this.syncMailListId : "",
        SyncCustomerLocationName:
          this.optionB1 == "false2" ? this.syncMailListName : "",
        SyncSuppressionEnabled: this.enableSuppression,
        SyncSuppressionOverwriteList:
          this.enableOverwriteExistingSuppressionList,
        SyncSuppressionDaily: this.optionC1 == "true3" ? true : false,
        SyncSuppressionLocationId: this.syncSuppressionMailListId,
        SyncSuppressionLocationName: this.syncSuppressionMailListName,
      };
      await this.axios
        .post("/ws/Connector/SaveConnectorSyncOption", payload)
        .then(() => {
          this.$vs.notify({
            title: "Success",
            text: "Success",
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
          if (this.isKnownCustomerChange || this.isSuppressionChange) {
            this.isSyncOptionsChangesInfoPopup = true;
          }
          this.cancelSyncOptions();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
        });
    },
    openURLRemoveConfirmPopup() {
      // Obtain the object reference for the <textarea>
      var txtarea = document.getElementById("mytextarea");

      // Obtain the index of the first selected character
      var start = txtarea.selectionStart;

      // Obtain the index of the last selected character
      var finish = txtarea.selectionEnd;

      // Obtain the selected text
      var sel = txtarea.value.substring(start, finish);

      if (sel) {
        this.urlSelectedToRemove = sel;
      }
      this.confirmationToRemovePostBackUrlPopup = true;
    },
    getSel() {
      // Obtain the object reference for the <textarea>
      var txtarea = document.getElementById("mytextarea");

      // Obtain the index of the first selected character
      var start = txtarea.selectionStart;

      // Obtain the index of the last selected character
      var finish = txtarea.selectionEnd;

      // Obtain the selected text
      var sel = txtarea.value.substring(start, finish);

      this.postBackUrlStr = txtarea.value.replace(sel, "");

      this.confirmationToRemovePostBackUrlPopup = false;
      this.urlSelectedToRemove = null;
    },
    checkForSyncOptionsValidation() {
      if (this.optionB1 == "false2" && this.syncMailListId == "") {
        this.knownSuppressionErrorMessage = "please select mail list.";
      } else {
        this.knownSuppressionErrorMessage = null;
        this.checkForChanges();
      }
    },
    checkForChanges() {
      if (
        (this.syncOptions.SyncCustomerEnabled == false &&
          this.enableKnownCustomer == true) ||
        (this.syncOptions.SyncCustomerEnabled == true &&
          this.enableKnownCustomer == false) ||
        (this.syncOptions.SyncCustomerOverwriteList == false &&
          this.enableOverwriteExistingKnownCustomerList == true) ||
        (this.syncOptions.SyncCustomerOverwriteList == true &&
          this.enableOverwriteExistingKnownCustomerList == false) ||
        this.syncOptions.SyncCustomerLocationId != this.syncMailListId ||
        (this.syncOptions.SyncCustomerDaily == true &&
          this.optionA1 != "true1") ||
        (this.syncOptions.SyncCustomerLocationId == "" &&
          this.optionB1 == "false2") ||
        (this.syncOptions.SyncCustomerLocationId != "" &&
          this.optionB1 == "true2")
      ) {
        this.isKnownCustomerChange = true;
      } else {
        this.isKnownCustomerChange = false;
      }
      if (
        (this.syncOptions.SyncSuppressionEnabled == false &&
          this.enableSuppression == true) ||
        (this.syncOptions.SyncSuppressionEnabled == true &&
          this.enableSuppression == false) ||
        (this.syncOptions.SyncSuppressionOverwriteList == false &&
          this.enableOverwriteExistingSuppressionList == true) ||
        (this.syncOptions.SyncSuppressionOverwriteList == true &&
          this.enableOverwriteExistingSuppressionList == false) ||
        this.syncOptions.SyncSuppressionLocationId !=
          this.syncSuppressionMailListId ||
        (this.syncOptions.SyncSuppressionDaily == true &&
          this.optionC1 != "true3")
      ) {
        this.isSuppressionChange = true;
      } else {
        this.isSuppressionChange = false;
      }
      this.saveSyncContacts();
      // if (this.isKnownCustomerChange && this.isSuppressionChange) {
      //   this.isKnownCustomerWarningMessagePopup = true;
      //   this.syncOptionChangeOverwriteMessage =
      //     "The changes made will will overwrite your Known Customer and Suppression List.  Do you wish to continue?";
      // } else if (this.isKnownCustomerChange || this.isSuppressionChange) {
      //   if (this.isKnownCustomerChange) {
      //     this.isKnownCustomerWarningMessagePopup = true;
      //     this.syncOptionChangeOverwriteMessage =
      //       "The changes made will will overwrite your Known Customer List.  Do you wish to continue?";
      //   }
      //   if (this.isSuppressionChange) {
      //     this.isKnownCustomerWarningMessagePopup = true;
      //     this.syncOptionChangeOverwriteMessage =
      //       "The changes made will will overwrite your Suppression List.  Do you wish to continue?";
      //   }
      // } else {
      //   this.saveSyncContacts();
      // }
    },
  },
};
</script>
